.unauthorized{
    position: relative;
    width: 100%;
    height: 35vh;
}
.element{
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    position: absolute;
}
.element h3{
    color:#1D3557;
    text-align: center;
}
.element img{
    width:45px;
    height: 45px;
}
.unauthorized p{
    color: #1D3557;
    text-align: center;
}
.unauthorized p span a{
    font-weight: 600;
    font-size: 1rem;
}
@keyframes rotate {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}