footer{
    width: 95%;
    max-width: 1050px;
    margin: auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 1.5rem;
    color: #495464;
    border-top: 0.1rem solid #495464;
    text-align: center;
}