.navbar{
    width: 100%;
    margin: auto;
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding:1.5rem 5% 0.1rem 5%;
    border-bottom: 0.1rem solid #495464;
}
.logo{
    width:60px;
    height: 60px;
    cursor:pointer;
}
nav{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 7%;
}
nav ul{
    width: 100%;
    max-width: 730px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
nav ul a li{
    font-size: 0.8rem;
}
.rightBlock{
    width: 100%;
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
}
.currPage li{
    font-weight: 700;
}
.right{
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.shopNav{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}
.hamburger,.close{
    display: none;
}
.close{
    position: fixed;
    right: 5%;
    z-index: 1000;
}
@media screen and (max-width:750px){
    .hamburger{
        display: block;
    }
    nav ul{
        width: 100%;
        z-index: 100;
        flex-direction: column;
        gap: 6rem;
        justify-content: center;
        position: fixed;
        right: 0;
        bottom: 0;
        top:0;
        background-color: #FFFBF4;
        transform: translateX(100%);
        transition: 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
    }
    nav ul a li{
        color: #495464;
    }
    nav ul a {
        width: 100%;
        max-width:150px;
        text-align: center;
        padding: 0.5rem;
    }
    .currPage{
        border: 0.1rem solid #FFFBF4;
        border-radius: 0.3rem;
    }
    .hamburger, .close{
        display: block;
        cursor: pointer;
    }
    .responsiveNav{
        transform: translateX(0);
    }
    .right{
        flex-direction: column;
        gap: 2rem;
    }
}