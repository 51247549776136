.alert{
    width: 80%;
    max-width: 400px;
    margin: auto;
    padding: 0.7rem;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1.2rem;
    align-items: center;
    font-weight: 600;
    border-radius: 0.3rem;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 1;
    animation: successful 2s ease-in-out;
}
.alert p{
    font-size: 0.9rem;
}
.success{
    background-color: #1D3557;
}
.failure{
    background-color: red;
}
@keyframes successful {
    0%{
        transform: translateY(0%);
    }
    50%{
        transform: translateY(200%);
    }
    100%{
        transform: translateY(0%);
    }
}